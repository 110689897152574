// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/chris/development/the-dream-shop-faq/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/chris/development/the-dream-shop-faq/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/chris/development/the-dream-shop-faq/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-fraud-protection-js": () => import("/Users/chris/development/the-dream-shop-faq/src/pages/fraud-protection.js" /* webpackChunkName: "component---src-pages-fraud-protection-js" */),
  "component---src-pages-get-started-js": () => import("/Users/chris/development/the-dream-shop-faq/src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("/Users/chris/development/the-dream-shop-faq/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("/Users/chris/development/the-dream-shop-faq/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-risks-js": () => import("/Users/chris/development/the-dream-shop-faq/src/pages/risks.js" /* webpackChunkName: "component---src-pages-risks-js" */)
}

exports.data = () => import("/Users/chris/development/the-dream-shop-faq/.cache/data.json")

