module.exports = [{
      plugin: require('/Users/chris/development/the-dream-shop-faq/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-59467680-2"},
    },{
      plugin: require('/Users/chris/development/the-dream-shop-faq/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/chris/development/the-dream-shop-faq/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
